import imgT1 from "../assets/image/jpg/portfolio/skf.jpg";
import imgT2 from "../assets/image/jpg/portfolio/ubm.jpg";
import imgT3 from "../assets/image/jpg/portfolio/cvi.jpg";
import imgT4 from "../assets/image/jpg/portfolio/earth-action-trust.jpg";
import imgT5 from "../assets/image/jpg/portfolio/fixonsite.jpg";
import imgT6 from "../assets/image/jpg/portfolio/regency.jpg";
import imgT7 from "../assets/image/jpg/portfolio/build4u.jpg";
import imgT8 from "../assets/image/jpg/portfolio/gv.jpg";
import imgT9 from "../assets/image/jpg/portfolio/statix.jpg";
import imgT10 from "../assets/image/jpg/portfolio/fss.jpg";
import imgT11 from "../assets/image/jpg/portfolio/yaarvellyproductions.jpg";
import imgT12 from "../assets/image/jpg/portfolio/jimmysfresh.jpg";

export const designWorks1 = [
  {
    brand: `Statix Electrical`,
    slug: `statix-electrical`,
    title: `Statix Electrical and Security LTD`,
    categories: [`web-design`,`support`],
    thumbnail: imgT9,
    url: `https://statix.co.nz/`
  },
  {
    brand: `Flow Solution Services`,
    slug: `flow-solution-services`,
    title: `Residential and Commercial Plumbing, Gas and Drainage Services.`,
    categories: [`web-design`,`support`],
    thumbnail: imgT10,
    url: `https://flowsolution.co.nz/`
  },
  {
    brand: `Yaar Velly Productions`,
    slug: `yaar-velly-productions`,
    title: `Punjabi music production company`,
    categories: [`web-design`,`support`],
    thumbnail: imgT11,
    url: `https://yaarvellyproductions.com/`
  },
  {
    brand: `Jimmys Fresh Produce`,
    slug: `jimmys-fresh-produce`,
    title: `New Zealand farm located in Pukekohe`,
    categories: [`web-design`,`support`,`logo-design`],
    thumbnail: imgT12,
    url: `https://jimmysfresh.co.nz/`
  },
  {
    brand: `Savi Kahlon Films`,
    slug: `savi-kahlon-films`,
    title: `Photographers & Videographers based in Auckland`,
    categories: [`web-design`,`support`],
    thumbnail: imgT1,
    url: `https://savikahlonfilms.co.nz/`
  },
  {
    brand: `Universal Building Maintenance`,
    slug: `universal-building-maintenance`,
    title: `One stop solution to all your Renovation and Painting requirements.`,
    categories: [`web-design`,`support`,`logo-design`],
    thumbnail: imgT2,
    url: `https://ubm.co.nz/`
  },
  {
    brand: `CVI Auckland`,
    slug: `cvi-auckland`,
    title: `If we do not have the car you want then we can order one of your choice`,
    categories: [`web-design`,`support`,`logo-design`],
    thumbnail: imgT3,
    url: `https://www.cviauckland.co.nz/`
  },
  {
    brand: `Earth Action Trust`,
    slug: `earth-action-trust`,
    title: `Community environmental organisation working towards the zero waste vision`,
    categories: [`web-design`,`support`],
    thumbnail: imgT4,
    url: `https://earthactiontrust.org.nz/`
  },
  {
    brand: `Fix on site`,
    slug: `fix-on-site`,
    title: `Experts in Phone, Tablet and Computer repair.`,
    categories: [`web-design`,`support`],
    thumbnail: imgT5,
    url: `https://fixonsite.nz/`
  },
  {
    brand: `Regency Painting`,
    slug: `regency-painting`,
    title: `Painting and decorating services for the Auckland region`,
    categories: [`web-design`,`support`],
    thumbnail: imgT6,
    url: `https://regency.kiwi/`
  },
  {
    brand: `Build For You`,
    slug: `build-for-you`,
    title: `Custom home builders`,
    categories: [`web-design`,`support`,`logo-design`],
    thumbnail: imgT7,
    url: `https://build4u.nz/`
  },
  {
    brand: `Garden Veges`,
    slug: `garden-veges`,
    title: `Fresh Strawberry and Vegetables, pick your own`,
    categories: [`web-design`,`support`],
    thumbnail: imgT8,
    url: `https://gardenveges.co.nz/`
  }
];
