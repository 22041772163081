import React from "react";
import { Element } from "react-scroll";
import imgFavicon from "../assets/image/jpg/fav.jpg";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing1/Hero";
import About from "../sections/landing1/About";
import Works from "../sections/landing1/Works";
import Contact from "../sections/landing1/Contact";

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Home | Nulane New Zealand</title>
          <meta name="keywords" content="Nulane New Zealand" />
          <meta name="description" content="Nulane is an Auckland-based team of Web Designers and Developers, that provides cost-effective web development, SEO and Hosting Services." />
          <link rel="icon" type="image/jpg" href={imgFavicon} />
          <html lang="en" />
        </Helmet>
        <Hero />
        <Element name="works">
          <Works />
        </Element>
        <About />
        <Contact />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
