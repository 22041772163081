import React from "react";
import { rgba } from "polished";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text, Span, List } from "../../components/Core";
import { device } from "../../utils";

const PlanCard = styled.div`
  border-radius: 10px;
  background: linear-gradient(135deg, #4f0ce8 1%,#6973fb 99%);
  padding: 30px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  // @media ${device.lg} {
  //   margin-top: 50px;
  // }
`;



const About = () => {
  return (
    <>
      <Section >
        <Container>
          <PlanCard>
          <Row>
            <Col lg="12">
              <div className="pl-lg-0 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                We are an Auckland-based team of web designers, developers, and marketers.
                </Title>
                <Text
                  color="light"
                  className="mt-2 mt-lg-3"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  In today's age, the world has become a global market, and your company needs a strong online presence. We're your one-stop digital shop for achieving real-world marketing results. Our team focuses on providing you with one-of-a-kind and cost-effective web design, development, SEO, hosting, and support services. Now, you can have enough time to focus on more relevant things while your website experiences improve without you even looking into it.
                </Text>
                
                </div>
              
            </Col>
          </Row>
          </PlanCard>
        </Container>
      </Section>
    </>
  );
};

export default About;
